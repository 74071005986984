<template>
  <div class="Rule">
    <div class="formWrap">
      <span class="formName">规则名称</span>
      <el-input
        class="formBody"
        v-model="ruleName"
        clearable
        placeholder="请输入内容"
      ></el-input>
      <span class="formName">设备类型</span>
      <el-select
        class="formBody"
        v-model="deviceType"
        placeholder="请选择"
        clearable
      >
        <el-option label="扬尘噪音" :value="1" />
        <el-option label="塔式起重机" :value="2" />
        <el-option label="施工升降机" :value="3" />
      </el-select>
      <span class="formName">设备厂商</span>
      <el-select
        class="formBody"
        v-model="manufacturerId"
        placeholder="请选择"
        clearable
      >
        <el-option
          v-for="(item, index) in manufacturerList"
          :key="index"
          :label="item.manufacturerName"
          :value="item.id"
        />
      </el-select>
      <span class="formName">数据消费方</span>
      <el-select
        class="formBody"
        v-model="thirdPartyId"
        placeholder="请选择"
        clearable
      >
        <el-option
          v-for="(item, index) in thirdPartyList"
          :key="index"
          :label="item.sourceTitle"
          :value="item.id"
        />
      </el-select>
      <span class="formName">模型分类</span>
      <el-select
        class="formBody"
        v-model="deviceDateType"
        clearable
        placeholder="请选择"
      >
        <el-option label="实时数据" :value="1" />
        <el-option label="报警数据" :value="2" />
        <el-option label="工作循环数据" :value="3" />
        <el-option label="司机数据" :value="4" />
      </el-select>

      <el-button class="mr10" type="primary" @click="onList">查询</el-button>
      <el-button @click="onReList">重置</el-button>
    </div>

    <el-button type="primary" icon="el-icon-plus" @click="handleAdd()"
      >添加</el-button
    >

    <el-table :data="listData" class="list">
      <el-table-column type="index" width="50"> </el-table-column>
      <el-table-column label="规则名称" prop="ruleName"></el-table-column>
      <el-table-column label="设备类型" prop="deviceType">
        <template slot-scope="scope">
          <span v-if="scope.row.deviceType == 1">扬尘噪音</span>
          <span v-if="scope.row.deviceType == 2">塔式起重机</span>
          <span v-if="scope.row.deviceType == 3">施工升降机</span>
        </template>
      </el-table-column>
      <el-table-column
        label="设备厂商"
        prop="manufacturerName"
      ></el-table-column>
      <el-table-column label="数据消费方" prop="sourceTitle"></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        label="修改时间"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @current-change="onList"
      :current-page.sync="pageNum"
      :page-size="10"
      layout="total, prev, pager, next"
      :total="totals"
    >
    </el-pagination>

    <el-dialog title="分发规则" :visible.sync="showFormData" @close="handleClose" fullscreen>
      <el-descriptions title="规则详情" :column="5" border>
        <el-descriptions-item label="规则名称">
          <el-input
            class="formBody"
            v-model="formData.ruleName"
            clearable
            placeholder="请输入内容"
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="设备类型">
          <el-select
            class="formBody"
            v-model="formData.deviceType"
            placeholder="请选择"
            clearable
          >
            <el-option label="扬尘噪音" :value="1" />
            <el-option label="塔式起重机" :value="2" />
            <el-option label="施工升降机" :value="3" />
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="设备厂商">
          <el-select
            class="formBody"
            v-model="formData.manufacturerId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="(item, index) in manufacturerList"
              :key="index"
              :label="item.manufacturerName"
              :value="item.id"
            />
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="物联数据模型">
          <el-select
            class="formBody"
            v-model="formData.dataTypeId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="(item, index) in dataTypeList"
              :key="index"
              :label="item.dataTypeName"
              :value="item.id"
            />
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="数据消费方">
          <el-select
            class="formBody"
            v-model="formData.thirdPartyId"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="(item, index) in thirdPartyList"
              :key="index"
              :label="item.sourceTitle"
              :value="item.id"
            />
          </el-select>
        </el-descriptions-item>
      </el-descriptions>

      <el-table
        :data="fieldRelation"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column
          label="数据消费方字段名">
          <template slot-scope="scope">
            <el-input
              style="width: 100%"
              v-model="scope.row.name"
              clearable
              placeholder="请输入内容"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          label="数据类型"
        >
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.type"
              placeholder="请选择"
              clearable
              style="width: 100%;"
            >
              <el-option
                v-for="(item, index) in typeList"
                :key="index"
                :label="item.dataType"
                :value="item.dataType"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          label="原始数据字段名"
        >
          <template slot-scope="scope">
            <el-select
              v-model="scope.row.origin"
              placeholder="请选择"
              clearable
              style="width: 100%;"
            >
              <el-option
                v-for="(item, index) in originList"
                :key="index"
                :label="item"
                :value="item"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-delete" @click="onDelRow(scope)">删除字段映射</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-button icon="el-icon-plus" @click="onAddRow" style="width: 100%;">新增字段映射</el-button>
      
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
    
    <style lang="less" scoped>
.Rule {
  padding: 20px;
}
</style>
    
<script>
export default {
  name: "Rule",
  components: {},
  data() {
    return {
      ruleName: null,
      thirdPartyId: null,
      manufacturerId: null,
      deviceType: null,
      deviceDateType: null,

      listData: null,
      pageNum: 1,
      pageSize: 10,
      totals: 0,

      thirdPartyList: null,
      manufacturerList: null,

      formData: {
        id: null,
        thirdPartyId: null,
        manufacturerId: null,
        ruleName: null,
        deviceType: null,
        dataSTypeMap: null,
        fieldMap: null,
        dataTypeId: null,
        deviceDateType: null,
      },
      showFormData: false,
      originList: [],
      typeList: null,
      fieldRelation: [],
      dataTypeList: null,
    };
  },
  computed: {},
  watch: {
    "formData.manufacturerId": {
      handler (newVal, oldVal) {
        this.onGetDataTypeList(this.formData.manufacturerId)
        this.formData.dataTypeId = null
      }
    },
    "formData.dataTypeId": {
      handler (newVal, oldVal) {
        this.onGetOriginList()
        this.fieldRelation.forEach(item => (
          item.origin = ""
        ))
      }
    },
    fieldRelation: {
      handler (newVal, oldVal) {
        this.formData.dataSTypeMap = {}
        this.formData.fieldMap = {}
        this.fieldRelation.forEach(item => {
          this.formData.fieldMap[item.name] = item.origin
          this.formData.dataSTypeMap[item.name] = item.type
        });
      },
      deep: true
    },
    thirdPartyId: function (newVal, oldVal) {
      this.pageNum = 1
      this.onList()
    },
    manufacturerId: function (newVal, oldVal) {
      this.pageNum = 1
      this.onList()
    },
    deviceType: function (newVal, oldVal) {
      this.pageNum = 1
      this.onList()
    },
    deviceDateType: function (newVal, oldVal) {
      this.pageNum = 1
      this.onList()
    },
  },
  mounted() {
    this.onList();
    this.onGetManufacturerList();
    this.onGetThirdPartyList();
    this.onGetTypeList()
  },
  methods: {
    goto: function (path) {
      this.$router.push({
        name: path,
      });
    },
    // 获取列表数据
    onList: function () {
      var that = this;
      var data = {
        ruleName: this.ruleName,
        thirdPartyId: this.thirdPartyId,
        manufacturerId: this.manufacturerId,
        deviceType: this.deviceType,
        deviceDateType: this.deviceDateType,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };

      if (!data.ruleName) data.ruleName = "";
      if (!data.thirdPartyId) data.thirdPartyId = "";
      if (!data.manufacturerId) data.manufacturerId = "";
      if (!data.deviceType) data.deviceType = "";

      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/rule/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.listData = res.data.list;
          that.totals = res.data.totals;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 重置
    onReList: function () {
      this.ruleName = null;
      this.thirdPartyId = null;
      this.manufacturerId = null;
      this.deviceType = null;
      this.deviceDateType = null;
      this.onList();
    },
    // 获取厂商列表
    onGetManufacturerList: function () {
      var that = this;
      var data = {
        manufacturerName: "",
        pageNum: 0,
        pageSize: 0,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/manufacturer/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.manufacturerList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取数据消费方列表
    onGetThirdPartyList: function () {
      var that = this;
      var data = {
        sourceTitle: "",
        pageNum: 0,
        pageSize: 0,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/thirdParty/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.thirdPartyList = res.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 初始化标单数据
    fromDataInit: function () {
      this.formData.id = null;
      this.formData.thirdPartyId = null;
      this.formData.manufacturerId = null;
      this.formData.ruleName = null;
      this.formData.deviceType = null;
      this.formData.dataSTypeMap = null;
      this.formData.fieldMap = null;
    },
    // 添加
    // handleAdd: function () {},
    // 获取数据源字段列表数据（已修改）
    // 改为根据数据模型 id 获取数据源字段列表数据
    onGetOriginList: function () {
      var that = this;
      if (!that.formData.dataTypeId) {
        that.originList.splice(0)
        that.formData.deviceDateType = null
        return
      }
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/dataTypeFormat/vendor-field",
          data: JSON.stringify({
            id: that.formData.dataTypeId
          }),
        })
        .then((res) => {
          console.log('onGetOriginList', res.data);
          that.originList = res.data.field;
          that.formData.deviceDateType = res.data.dataType;
          that.originList.push("")
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取数据类型列表数据
    onGetTypeList: function () {

      var that = this;
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/dataTypeDict/data-type",
          // data: JSON.stringify(data),
        })
        .then((res) => {
          that.typeList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 添加字段映射
    onAddRow: function () {
      this.fieldRelation.push({
        origin: '',
        type: '',
        name: ''
      })
    },
    // 删除字段映射
    onDelRow: function (index) {
      this.fieldRelation.splice(index.$index, 1)
    },
    // 添加
    handleAdd: function () {
      this.handleInit()
      this.showFormData = true
    },
    // 编辑
    handleEdit: function (row) {
      this.handleInit()
      this.formData.id = row.id
      this.formData.thirdPartyId = row.thirdPartyId
      this.formData.manufacturerId = row.manufacturerId
      this.formData.ruleName = row.ruleName
      this.formData.deviceType = row.deviceType
      this.formData.deviceDateType = row.deviceDateType
      setTimeout(() => {
        this.formData.dataTypeId = row.dataTypeId
        setTimeout(() => {
        for (var item in row.dataSTypeMap) {
          var obj = {
            name: item,
            type: row.dataSTypeMap[item],
            origin: row.fieldMap[item]
          }
          this.fieldRelation.push(obj)
        }
      }, 500);
      }, 500);
      this.showFormData = true
    },
    // 取消
    handleClose: function () {
      this.handleInit()
      this.showFormData = false
    },
    // 初始化
    handleInit: function () {
      this.formData.id = null
      this.formData.thirdPartyId = null
      this.formData.manufacturerId = null
      this.formData.ruleName = null
      this.formData.deviceType = null
      this.formData.dataSTypeMap = null
      this.formData.fieldMap = null
      this.formData.dataTypeId = null
      this.formData.deviceDateType = null
      this.originList.splice(0)
      this.fieldRelation.splice(0)
    },
    // 确定
    handleConfirm: function () {
      this.$confirm("此操作将保存数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onConfirm();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 提交
    onConfirm: function () {
      var that = this;
      var data = this.formData;
      if (!data.ruleName) {
        this.$message({
          type: "warning",
          message: '请填写规则名称',
        });
        return
      }
      if (!data.deviceType) {
        this.$message({
          type: "warning",
          message: '请选择设备类型',
        });
        return
      }
      if (!data.manufacturerId) {
        this.$message({
          type: "warning",
          message: '请填写规则名称',
        });
        return
      }
      if (!data.thirdPartyId) {
        this.$message({
          type: "warning",
          message: '请选择数据消费方',
        });
        return
      }
      var checkRule = true
      this.fieldRelation.forEach((item, index) => {
        if (!item.name) {
          this.$message({
            type: "warning",
            message: `请填写第 ${index+1} 行数据消费方字段名`,
          });
          return checkRule = false
        }
        if (!item.type) {
          this.$message({
            type: "warning",
            message: `请选择第 ${index+1} 行数据类型`,
          });
          checkRule = false
          return checkRule = false
        }
      })
      if (!checkRule) return
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/rule/submit",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.handleClose()
            this.onList()
          } else {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 删除
    handleDel: function (row) {
      this.$confirm("此操作将删除数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.onDel(row);
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    // 执行删除
    onDel: function (row) {
      var that = this;
      var data = {
        id: row.id,
        thirdPartyId: row.thirdPartyId
      }
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/rule/remove",
          data: JSON.stringify(data),
        })
        .then((res) => {
          if (res.success) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.onList()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取数据模型列表数据
    onGetDataTypeList: function (id) {
      var that = this;
      var data = {
        // dataTypeName: "",
        manufacturerId: id,
        // deviceDateType: this.deviceDateType,
        pageNum: 0,
        pageSize: 0,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/qingxin-platform-iot/dataTypeFormat/list",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.dataTypeList = res.data.list;
          // that.totals = res.data.totals;
        })
        .catch((err) => {
          console.log(err);
        });
    }    
  },
};
</script>
    